import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PortfolioItem from "../../components/portfolio-item";

import '../../components/index.scss'
import HomeLink from "../../components/home-link";

const EmojiplyPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "portfolio/emojiply/icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero01: file(relativePath: { eq: "portfolio/emojiply/01.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero02: file(relativePath: { eq: "portfolio/emojiply/02.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero03: file(relativePath: { eq: "portfolio/emojiply/03.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title = 'emojiply';
  const summary = `A side project that holds a special place in my heart. Born out of a request for help from a friend
  after a close relative developed a temporal speech impediment, emojiply works as a customizable answer board that
  leverages emojis to get your point across.`;

  return <Layout>
    <SEO title={title} description={summary}/>
    <HomeLink/>
    <PortfolioItem
      year={2020}
      role={'Product Engineer'}
      frameworks="UIKit, State Restoration, On-Device data persistence, Localization"
      imageQuery={imageQuery}
      name={title}
      stack="Swift"
      url={'https://apps.apple.com/us/app/emojiply/id1539841010'}
      summary={summary}
    />
  </Layout>
};

export default EmojiplyPage
